<template>
  <div class="bryntum-calendar-wrapper">
    <div class="dates-range">
      <div class="dates-range-left">
        <base-button class="colored" @click="goTo(-30)">
          <i class="far fa-angle-double-left"></i> 30
        </base-button>
        <base-button class="outline" @click="goTo(-7)">
          <i class="far fa-angle-double-left"></i> 7
        </base-button>
      </div>
      <div class="dates-range-center">
        <base-button class="outline" @click="today">
          <i class="far fa-angle-double-right"></i>
          {{ $t("COMMON.TODAY") }}
          <i class="far fa-angle-double-left"></i>
        </base-button>
      </div>
      <div class="dates-range-right">
        <base-button class="outline" @click="goTo(7)">
          7 <i class="far fa-angle-double-right"></i>
        </base-button>
        <base-button class="colored" @click="goTo(30)">
          30 <i class="far fa-angle-double-right"></i>
        </base-button>
      </div>
    </div>
    <bryntum-scheduler
      v-bind="schedulerConfig"
      :events="events"
      :resources="resources"
      :end-date="endDate"
      event-style="rounded"
      :start-date="startDate"
      :timeRangesFeature="true"
      :timeRanges="timeRanges"
      :key="renderKey"
    />
    <div class="legend">
      <h3>{{ $t("COMMON.LEGENDS") }}:</h3>
      <div class="legend-items">
        <ul class="legend-item guaranteed">
          <li class="circle"></li>
          <li>{{ $t("COMMON.GUARANTEED_RESERVATION") }}</li>
        </ul>
        <ul class="legend-item not-guaranteed">
          <li class="circle"></li>
          <li>{{ $t("COMMON.NOT_GUARANTEED_RESERVATION") }}</li>
        </ul>
        <ul class="legend-item arrived">
          <li class="circle"></li>
          <li>{{ $t("COMMON.ARRIVED") }}</li>
        </ul>
        <ul class="legend-item left">
          <li class="circle"></li>
          <li>{{ $t("COMMON.LEFT") }}</li>
        </ul>
        <ul class="legend-item balance-due">
          <li class="circle"></li>
          <li>{{ $t("COMMON.BALANCE_DUE") }}</li>
        </ul>
        <ul class="legend-item blocked">
          <li class="circle"></li>
          <li>{{ $t("COMMON.BLOCKED") }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { BryntumScheduler } from "@bryntum/scheduler-vue";
import { StringHelper, DateHelper } from "@bryntum/scheduler";
import { CONDITION_CLEAN } from "@/constants/spots";
import CalendarTooltipComponent from "./CalendarTooltipComponent.vue";
import moment from "moment";
import { BOOKING_STATUS_OUT_OF_SERVICE } from "../constants/common";

export default {
  name: "calendar-component",

  components: { BryntumScheduler, CalendarTooltipComponent },

  props: {
    spots: {
      type: Array,
      required: true,
    },
  },

  data() {
    const startDate = DateHelper.add(new Date(), -5, "days");
    const endDate = DateHelper.add(new Date(), 30, "days");

    return {
      CONDITION_CLEAN,
      selectedEventRecord: {},
      startDate,
      endDate,
      renderKey: 1,
    };
  },

  computed: {
    resources: function () {
      return this.spots.map((spot) => ({
        id: spot.id,
        name: spot.code,
        clean: spot.clean_condition == this.CONDITION_CLEAN,
      }));
    },
    timeRanges: function () {
      const timeRanges = [];

      if (this.startDate < new Date()) {
        timeRanges.push({
          id: 1,
          cls: "shaded",
          startDate: this.startDate,
          endDate: new Date(),
        });
      }

      return timeRanges;
    },
    // startDate: function () {
    //   const date = new Date();
    //   return new Date(date.getFullYear(), date.getMonth(), 1);
    // },
    // endDate: function () {
    //   const date = new Date();
    //   return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    // },
    events: function () {
      let events = [];

      this.spots.forEach((spot) => {
        events = [
          ...events,
          ...spot.bookings.map((booking) => {
            const personCapacity =
              Number(booking.adults_count ?? 0) +
              Number(booking.children_count ?? 0);

            let cls = "not-guaranteed";
            let status = "NOT_GUARANTEED_RESERVATION";
            let iconCls = "fas fa-truck";
            let customer = null;
            let resizable = false;

            if (booking.is_blocked) {
              cls = "blocked";
              status = "BLOCKED";
            }

            if (booking.status == BOOKING_STATUS_OUT_OF_SERVICE) {
              cls = "out-of-service";
              status = BOOKING_STATUS_OUT_OF_SERVICE;
              iconCls = null;
            } else {
              customer = `${booking.customer.firstname} ${booking.customer.lastname}`;
            }

            const bookingDays = moment(booking.end_at).diff(
              booking.start_at,
              "days"
            );

            return {
              id: booking.id,
              resourceId: spot.id,
              startDate: booking.start_at,
              endDate: booking.end_at,
              iconCls,
              personCapacity,
              status,
              cls,
              bookingDays,
              customer,
              reason: booking.out_of_service_reason,
              resizable,
              draggable: false,
            };
          }),
        ];
      });

      return events;
    },
    schedulerConfig: function () {
      return {
        viewPreset: {
          base: "dayAndMonth",
          timeResolution: {
            unit: "day",
            increment: 1,
          },
        },
        scheduleTooltipFeature: false,
        rowHeight: 40,
        barMargin: 5,
        tickSize: 40,
        zoomOnTimeAxisDoubleClick: false,
        zoomOnMouseWheel: false,
        getDateConstraints(resourceRecord, eventRecord) {
          return {
            start: new Date(),
          };
        },
        eventEditFeature: {
          editorConfig: {
            header: false,
            autoHide: false,
            flex: "col",
            cls: "view-event",
            items: {
              nameField: null,
              resourceField: null,
              startDateField: null,
              startTimeField: null,
              endDateField: null,
              endTimeField: null,
              container: {
                type: "container",
                items: {
                  selectedPeriodSection: {
                    type: "container",
                    items: {
                      selectedPeriodLabel: {
                        type: "label",
                        cls: "font-weight-bold",
                        text: this.$t("BOOKINGS.SELECTED_PERIOD"),
                      },
                      selectedPeriodValue: {
                        type: "label",
                      },
                    },
                  },
                  customerSection: {
                    type: "container",
                    items: {
                      customerIcon: {
                        type: "label",
                        html: '<i class="fas fa-user"></i>',
                      },
                      customerName: {
                        type: "label",
                        cls: "font-weight-bold",
                      },
                    },
                  },
                  statusSection: {
                    type: "container",
                    items: {
                      statusLabel: {
                        type: "label",
                        cls: "font-weight-bold",
                        text: this.$t("COMMON.STATUS"),
                      },
                      statusValue: {
                        type: "label",
                      },
                    },
                  },
                  reasonSection: {
                    type: "container",
                    items: {
                      reasonLabel: {
                        type: "label",
                        cls: "font-weight-bold",
                        text: this.$t("COMMON.REASON"),
                      },
                      reasonValue: {
                        type: "label",
                      },
                    },
                  },
                  bookingDaysSection: {
                    type: "container",
                    items: {
                      bookingDaysLabel: {
                        type: "label",
                        cls: "font-weight-bold",
                        text: this.$t("BOOKINGS.BOOKING_DAYS"),
                      },
                      bookingDaysValue: {
                        type: "label",
                      },
                    },
                  },
                },
              },
            },
            bbar: {
              items: {
                saveButton: null,
                deleteButton: null,
                cancelButton: null,
                showDetailButton: {
                  type: "button",
                  cls: "btn btn-warning bg-warning text-white",
                  text: this.$t("COMMON.SHOW_DETAILS"),
                  onAction: this.viewBooking,
                },
                newBookingButton: {
                  type: "button",
                  cls: "btn btn-warning bg-warning text-white",
                  text: this.$t("COMMON.NEW_BOOKING"),
                  onAction: this.addBooking,
                },
                backIntoServiceButton: {
                  type: "button",
                  cls: "btn btn-warning bg-warning text-white",
                  text: this.$t("COMMON.PUT_BACK_INTO_SERVICE"),
                  onAction: this.putBackIntoService,
                },
                outOfServiceButton: {
                  type: "button",
                  cls: "btn btn-warning bg-warning text-white",
                  text: this.$t("COMMON.PUT_OUT_OF_SERVICE"),
                  onAction: this.putOutOfService,
                },
              },
            },
          },
        },
        listeners: {
          beforeEventEditShow: this.beforeEventEditShow,
          beforeEventResizeFinalize: this.beforeEventResizeFinalize,
        },
        eventTooltipFeature: false,

        columns: [
          {
            text: this.$t("COMMON.SPOTS"),
            // type: "resourceInfo",
            field: "name",
            width: 260,
            htmlEncode: false,
            renderer: ({ value, record }) =>
              StringHelper.xss`<div class="w-100 d-flex justify-content-between align-items-center"> <span>#${StringHelper.encodeHtml(
                value
              )}</span> <span class="${
                record.clean ? "clean" : "dirty"
              }"></span> </div>`,
          },
        ],

        columnLines: false,

        eventRenderer: ({ eventRecord }) => {
          return StringHelper.xss`<span class="d-inline-flex justify-content-center align-items-center"><span class="user"></span><small class="${
            eventRecord.personCapacity ? "" : "d-none"
          }">${eventRecord.personCapacity}</small></span>`;
        },
      };
    },
  },

  methods: {
    beforeEventEditShow({ editor, eventRecord }) {
      const newBookingButton = editor.widgetMap.bbar.widgetMap.newBookingButton;
      const outOfServiceButton =
        editor.widgetMap.bbar.widgetMap.outOfServiceButton;
      const showDetailButton = editor.widgetMap.bbar.widgetMap.showDetailButton;
      const backIntoServiceButton =
        editor.widgetMap.bbar.widgetMap.backIntoServiceButton;
      const container = editor.widgetMap.container;

      const selectedPeriodSection = container.widgetMap.selectedPeriodSection;
      const selectedPeriodValue = container.widgetMap.selectedPeriodValue;

      const customerSection = container.widgetMap.customerSection;
      const customerName = container.widgetMap.customerName;

      const statusSection = container.widgetMap.statusSection;
      const statusValue = container.widgetMap.statusValue;

      const bookingDaysSection = container.widgetMap.bookingDaysSection;
      const bookingDaysValue = container.widgetMap.bookingDaysValue;

      const reasonSection = container.widgetMap.reasonSection;
      const reasonValue = container.widgetMap.reasonValue;

      selectedPeriodSection.hidden = true;
      customerSection.hidden = true;
      statusSection.hidden = true;
      bookingDaysSection.hidden = true;
      reasonSection.hidden = true;

      newBookingButton.hidden = true;
      outOfServiceButton.hidden = true;
      showDetailButton.hidden = true;
      backIntoServiceButton.hidden = true;

      this.selectedEventRecord = { ...eventRecord };

      if (
        eventRecord.name == "New event" ||
        eventRecord.status == BOOKING_STATUS_OUT_OF_SERVICE
      ) {
        selectedPeriodSection.hidden = false;

        if (eventRecord.status == BOOKING_STATUS_OUT_OF_SERVICE) {
          if (moment().diff(eventRecord.endDate) < 0) {
            backIntoServiceButton.hidden = false;
          }

          reasonSection.hidden = false;
          reasonValue.text = this.$te(`BOOKINGS.REASON_${eventRecord.reason}`)
            ? this.$t(`BOOKINGS.REASON_${eventRecord.reason}`)
            : eventRecord.reason;
        }

        if (eventRecord.name == "New event") {
          newBookingButton.hidden = false;
          outOfServiceButton.hidden = false;
        }

        selectedPeriodValue.text = `${DateHelper.format(
          eventRecord.startDate,
          "DD MMM"
        )} - ${DateHelper.format(eventRecord.endDate, "DD MMM")}`;
      } else {
        showDetailButton.hidden = false;
        customerSection.hidden = false;
        statusSection.hidden = false;
        bookingDaysSection.hidden = false;

        customerName.text = eventRecord.customer;
        statusValue.text = this.$t(`COMMON.${eventRecord.status}`);
        statusValue.cls = eventRecord.cls;
        bookingDaysValue.text = eventRecord.bookingDays;
      }
    },
    beforeEventResizeFinalize(data) {
      console.log(data);
    },
    viewBooking({ source }) {
      source.parent.parent?.close();

      this.$emit("onViewBooking", this.selectedEventRecord.data.id);

      this.selectedEventRecord = {};
    },
    addBooking({ source }) {
      source.parent.parent?.close();

      this.$emit("onAddBooking", {
        spotId: this.selectedEventRecord.data.resourceId,
        startDate: DateHelper.format(
          this.selectedEventRecord._startDate,
          "YYYY-MM-DD"
        ),
        endDate: DateHelper.format(
          this.selectedEventRecord._endDate,
          "YYYY-MM-DD"
        ),
      });

      this.selectedEventRecord = {};
    },
    putOutOfService({ source }) {
      source.parent.parent?.close();

      const spotId = this.selectedEventRecord.data.resourceId;

      this.$emit("onOutOfService", {
        spotId,
        spot: this.getSpot(spotId),
        startDate: DateHelper.format(
          this.selectedEventRecord._startDate,
          "YYYY-MM-DD"
        ),
        endDate: DateHelper.format(
          this.selectedEventRecord._endDate,
          "YYYY-MM-DD"
        ),
      });

      this.selectedEventRecord = {};
    },

    putBackIntoService({ source }) {
      source.parent.parent?.close();

      const bookingId = this.selectedEventRecord.data.id;
      const spotId = this.selectedEventRecord.data.resourceId;

      const booking = this.getBooking(bookingId, this.getSpot(spotId));

      this.$emit("onBackIntoService", {
        bookingId,
        booking,
        startDate: this.selectedEventRecord._startDate,
        endDate: this.selectedEventRecord._endDate,
      });

      this.selectedEventRecord = {};
    },

    goTo(number) {
      this.startDate = DateHelper.add(this.startDate, number, "days");
      this.endDate = DateHelper.add(this.endDate, number, "days");
    },

    today() {
      const startDate = DateHelper.add(new Date(), -5, "days");
      const endDate = DateHelper.add(new Date(), 30, "days");

      if (this.startDate > startDate) {
        this.startDate = startDate;
        this.$nextTick(function () {
          this.endDate = endDate;
        });
      } else {
        this.endDate = endDate;
        this.$nextTick(function () {
          this.startDate = startDate;
        });
      }
    },

    getSpot(spotId) {
      return this.spots.find((spot) => spot.id == spotId);
    },

    getBooking(bookingId, spot) {
      return spot.bookings.find((booking) => booking.id == bookingId);
    },
  },

  watch: {
    startDate() {
      this.renderKey++;
    },
    endDate() {
      this.renderKey++;
    },
  },
};
</script>

<style lang="scss">
@import "~@bryntum/scheduler/scheduler.stockholm.css";

.clean,
.dirty,
.circle {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 9999px;
}

.clean {
  background-color: #e81212;
}

.dirty {
  background-color: #72e812;
}
.circle.not-guaranteed {
  background-color: var(--primary);
}

.b-timeline-subgrid .shaded {
  border-inline-end: 2px solid red;
  background-color: #fafafa8c;
}
.b-grid-header .b-sch-timerange {
  background-color: #fafafa8c;
}
.b-sch-range.shaded {
  background-color: transparent;
}
</style>
